var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.$apollo.loading,
          expression: "!$apollo.loading"
        }
      ],
      staticStyle: { width: "100%" }
    },
    [
      _c("file-pond", {
        ref: "pond",
        attrs: {
          "class-name": "title-image-file",
          "label-idle": _vm.label,
          "allow-multiple": false,
          allowPaste: false,
          allowReplace: true,
          dropOnElement: false,
          forceRevert: true,
          maxFiles: "1",
          files: _vm.files,
          allowFileTypeValidation: true,
          "accepted-file-types": _vm.allowedFileTypes,
          allowImageEditor: _vm.allowImageEditMode,
          imageEditor: _vm.editorSettings,
          allowImageFocalpointEdit: _vm.allowImageFocalpointEdit,
          "image-focalpoint-editor": _vm.imageFocalpointEditor,
          imageFocalpointSet: _vm.imageFocalpointSet,
          allowImageSizeMetadata: true,
          imageEditorAllowEdit: true,
          allowFileSizeValidation: _vm.allowFileSizeValidation,
          allowImageValidateSize: true,
          allowImagePreview: true,
          imageValidateSizeMinWidth: _vm.imageValidateSizeMinWidth,
          imageValidateSizeMaxWidth: _vm.imageValidateSizeMaxWidth,
          imageValidateSizeMinHeight: _vm.imageValidateSizeMinHeight,
          imageValidateSizeMaxHeight: _vm.imageValidateSizeMaxHeight,
          imageEditorAfterWriteImage: _vm.imageEditorAfterWriteImage,
          maxFileSize: _vm.maxFileSize,
          onerror: _vm.handleErrorMethod,
          server: { process: _vm.process, revert: _vm.revert, load: _vm.load },
          allowVideoPreview: true,
          allowAudioPreview: true,
          credits: "false"
        },
        on: {
          init: _vm.handleFilePondInit,
          initfile: _vm.initFileLoad,
          processfile: _vm.handleFilePondProcessFile,
          removefile: [
            _vm.removeFileFromFilePont,
            function($event) {
              return _vm.$emit("file-removed")
            }
          ]
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }